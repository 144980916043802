/* eslint-disable */
import axios from 'axios';
import { hideAlert, showAlert } from './alert';

export const fillUpForm = (el) => {
  cleanUpForm();
  let clickedTraining;
  if (el.target) {
    clickedTraining = JSON.parse(el.target.dataset.training);
  } else {
    clickedTraining = JSON.parse(el.dataset.training);
  }
  // console.log(clickedTraining);
  document.getElementById('input-name').value = clickedTraining.name;
  document.getElementById('input-title').value = clickedTraining.title;
  document.getElementById(
    'logo-cl'
  ).src = `https://mindlab-linkowisko-files.s3.eu-central-1.amazonaws.com/img/logo/${clickedTraining.logo}`;
  document.getElementById('groupheader').value = clickedTraining.groupheader;
  for (let i = 0; i < clickedTraining.links.length; i++) {
    document.getElementById(`input-link${i + 1}`).value =
      clickedTraining.links[i];
  }
};

export const cleanUpForm = () => {
  document.getElementById('input-name').value = '';
  document.getElementById('input-title').value = '';
  document.getElementById('logo').value = '';
  document.getElementById('logo-cl').src = '/img/logo/default.png';
  document.getElementById('groupheader').value = '';
  for (let i = 0; i < 15; i++) {
    document.getElementById(`input-link${i + 1}`).value = '';
  }
};
// export const createNewTraining = (name, title, links) => {

// };
// export const updateTraining = async (name, title, links) => {
//   const id = JSON.parse(document.getElementById(name).dataset.training)._id;
//   try {
//     const res = await axios({
//       method: 'PATCH',
//       url: `http://127.0.0.1:1337/api/v1/trainings/${id}`,
//       data: {
//         name,
//         title,
//         links,
//       },
//     });
//     if (res.data.status === 'success') {
//       showAlert('success', `Training updated successfully!`);
//     }
//   } catch (err) {
//     console.log(err);
//     showAlert('error', err.response.data.message);
//   }
// };

export const connectApi = async (type, data, name) => {
  let myMethod = '';
  const author = JSON.parse(
    document.getElementById('logged-user').dataset.user
  );
  data.append('author', author);
  let myUrl = '';
  if (type === 'create') {
    myMethod = 'POST';
    myUrl = 'create';
  } else if (type === 'update') {
    console.log(name);
    const id = JSON.parse(document.getElementById(name).dataset.training)._id;
    myMethod = 'PATCH';
    myUrl = id;
  }
  try {
    const res = await axios({
      method: myMethod,
      url: `/api/v1/trainings/${myUrl}`,
      data: data,
    });
    if (res.data.status === 'success') {
      showAlert('success', `Training updated successfully!`);
      localStorage.setItem('reload', `${name}`);
      window.setTimeout(() => {
        location.reload();
      }, 1000);
    }
  } catch (err) {
    console.log(err);
    showAlert('error', err.response.data.message);
  }
};

export const findTraining = async (id) => {
  try {
    const url = `/api/v1/trainings/${id}`;
    console.log(url);
    const res = await axios({
      method: 'GET',
      url: url,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Redirecting!');
      window.setTimeout(() => {
        location.assign(`/${id}`);
      }, 1000);
    }
  } catch (err) {
    console.log(err);
    showAlert('error', err.response.data.message);
  }
};

export const deleteTraining = async (el) => {
  //console.log(el);
  el.preventDefault();
  let name = el.target.id.split('del-')[1];
  console.log(name);
  const id = JSON.parse(document.getElementById(name).dataset.training)._id;
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/api/v1/trainings/${id}`,
    });
    //console.log(res.);
    if (res.status === 204) {
      showAlert('success', `Training deleted successfully!`);
      window.setTimeout(() => {
        location.reload();
      }, 1000);
    }
  } catch (err) {
    console.log(err);
    showAlert('error', err.response.data.message);
  }
};
