import '@babel/polyfill';
import { login, logout } from './login';
import {
  fillUpForm,
  connectApi,
  cleanUpForm,
  findTraining,
  deleteTraining,
} from './trener';
import { hideAlert, showAlert } from './alert';

const loginForm = document.querySelector('.form--login');
const saveForm = document.querySelector('.save-training');
const myTrainings = document.querySelectorAll('.training');
const cleanup = document.getElementById('cleanup');
const askTraining = document.querySelector('.form--asktraining');
const logOutBtn = document.querySelector('.nav__el--logout');
const logoPic = document.getElementById('logo');
if (logOutBtn) logOutBtn.addEventListener('click', logout);

if (cleanup) {
  cleanup.addEventListener('submit', (e) => {
    // console.log(cleanup);
    e.preventDefault();
    cleanUpForm();
  });
}
const myTrainingsNames = [];
if (loginForm) {
  // console.log('login panel');
  loginForm.addEventListener('submit', (e) => {
    // console.log('here i am');
    e.preventDefault();
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    login(email, password);
  });
}

const trainingHandler = (ev) => {
  if (ev.target.classList.contains('delete')) {
    deleteTraining(ev);
  }
  if (ev.target.classList.contains('training')) {
    fillUpForm(ev);
  }
};

if (myTrainings.length > 0) {
  const training_list = document.querySelector('.training_list');
  training_list.addEventListener('click', trainingHandler);
  myTrainings.forEach((el) => {
    myTrainingsNames[myTrainingsNames.length] = el.id;
  });
  const x = localStorage.getItem('reload');
  if (x) {
    // console.log(x);
    localStorage.removeItem('reload');
    const newClick = document.getElementById(x);
    // console.log(newClick);
    fillUpForm(newClick);
  }
}
// poniżej stara wersja z milionem eventlistenerow (popawiona)
// if (myTrainings.length > 0) {
//   myTrainings.forEach((el) => {
//     el.addEventListener('click', fillUpForm.bind(this, el));
//     const idToDel = `del-${el.id}`;
//     document
//       .getElementById(`del-${el.id}`)
//       .addEventListener('click', deleteTraining.bind(this, idToDel));
//     myTrainingsNames[myTrainingsNames.length] = el.id;
//     const x = localStorage.getItem('reload');
//     if (x) {
//       // console.log(x);
//       localStorage.removeItem('reload');
//       const newClick = document.getElementById(x);
//       //console.log(newClick);
//       fillUpForm(newClick);
//     }
//   });
// }

if (saveForm) {
  saveForm.addEventListener('submit', (e) => {
    // ('here i am ready to save');
    e.preventDefault();
    const form = new FormData();
    const name = document.getElementById('input-name').value;
    //console.log(name);
    form.append('name', name);
    form.append('title', document.getElementById('input-title').value);
    form.append('groupheader', document.getElementById('groupheader').value);
    // console.log(form);
    if (document.getElementById('logo').files[0])
      form.append('logo', document.getElementById('logo').files[0]);
    // console.log(title);

    const linksTemp = document.querySelectorAll('.input--link');

    Array.prototype.forEach.call(linksTemp, (el) => {
      if (el.value) {
        if (el.value.startsWith('www')) el.value = `http://${el.value}`;
        form.append('links', el.value);
        // consolelinks[links.length] = el.value;
      }
    });
    // links.forEach((item) => form.append('links', item));
    // console.log(form.getAll('links'));
    let type = '';

    if (
      myTrainingsNames.findIndex((el) => {
        return el === name;
      }) >= 0
    ) {
      type = 'update';
    } else {
      type = 'create';
    }

    connectApi(type, form, name);
  });
}

// na stronie staetowej, gormularz pytajacy o ID
if (askTraining) {
  // console.log('checkId');
  askTraining.addEventListener('submit', (el) => {
    //console.log('submitted');
    el.preventDefault();
    const checkId = document.getElementById('ask-input').value;
    //console.log(checkId);
    findTraining(checkId);
  });
}

if (logoPic) {
  logoPic.addEventListener('change', () => {
    // console.log(event);
    document.getElementById('logo-cl').src = window.URL.createObjectURL(
      logoPic.files[0]
    );
  });
}
